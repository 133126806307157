<template>
  <div class="notifications">
    <p class='notificationsHead'>Уведомления</p>
    <p></p>
    <div class="notificationsList"  v-if='notifications.length != 0'>
      <div class="notification" v-for="notify in notifications" v-bind:key="notify">
        <img src="../assets/successNotify.svg" v-if="notify.type == 'PAYMENT'"/>
        <img src="../assets/successNotify.svg" v-if="notify.type == 'PAYOUT' && notify.data.status == true"/>
        <img src="../assets/errorNotify.svg" v-if="notify.type == 'PAYOUT' && notify.data.status == false"/>
        <img src="../assets/errorNotify.svg" v-if="notify.type == 'ERROR'"/>
        <img src="../assets/verifyNotify.svg" v-if="notify.type == 'VERIFICATION'"/>
        <img src="../assets/infoNotify.svg" v-if="notify.type == 'TEXT'"/>
        <div class="notifyMessage">
          <p v-if="notify.type == 'PAYMENT'">{{ notify.message.split(" ")[0] }}</p>
          <p v-if="notify.type == 'PAYMENT'">{{ notify.message}} RUB!</p>
          <p v-if="notify.type == 'PAYOUT'">{{ notify.message }}</p>
          <p v-if="notify.type == 'PAYOUT' && notify.data.status == true">Вы успешно вывели {{notify.data.amount}} RUB!</p>
          <p v-if="notify.type == 'PAYOUT' && notify.data.status == false">Произошла ошибка при выводе!</p>
          <p v-if="notify.type == 'ERROR'"></p>
          <p v-if="notify.type == 'VERIFICATION'">Верификация</p>
          <p v-if="notify.type == 'VERIFICATION'">{{ notify.message }}</p>
          <p v-if="notify.type == 'TEXT'">Информация</p>
          <p v-if="notify.type == 'TEXT'">{{ notify.message }}</p>
          <div class='time'> <p class='timeDifference' style='font-size: 12px; font-weight: 400;'> {{ getCurrentDateTime(notify._id) }}</p></div>
        </div>
        
      </div>
      
    </div>
    <p v-else>Ожидаем новые уведомления.</p>
    
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'NotificationsComponent',
  data() {
    return {
      active: "profile",
      user: {},
      notifications: []
    }
  },
  methods: {
    loadNotifications() {
      axios.get(`https://${process.env.VUE_APP_SERVERHOST}/api/notification`, {
        headers: {
          Authorization: 'Bearer ' + this.user.token
        }
      })
      .then(res => {
        if (res.data.result.data.length != 0) {
          this.notifications = res.data.result.data
          this.getDimensions()
        }
      })
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
      
      if (this.width <= 968) {
        
        setTimeout(() => {
          let el = document.querySelector('.notificationsList')
          el.scrollTo(0, -99999);
        }, 50)
      }
    },
    getCurrentDateTime(_id) {

      let timestamp = _id.toString().substring(0,8)
      let date = new Date( parseInt( timestamp, 16 ) * 1000 )
      return this.timeDifference(date, Date.now())
    },
    timeDifference(d1, d2) {
      var units = {
        year  : 24 * 60 * 60 * 1000 * 365,
        month : 24 * 60 * 60 * 1000 * 365/12,
        day   : 24 * 60 * 60 * 1000,
        hour  : 60 * 60 * 1000,
        minute: 60 * 1000,
        second: 1000
      }
      var rtf = new Intl.RelativeTimeFormat('ru', { numeric: 'auto' })
      var elapsed = d1 - d2
      for (var u in units) 
        if (Math.abs(elapsed) > units[u] || u == 'second') 
          return rtf.format(Math.round(elapsed/units[u]), u)
    }
  },
  mounted() {
    this.user = this.$store.getters.getUserData
    if (this.$store.getters.getReadable) this.$store.commit('setReadable', false)
    this.loadNotifications()
  }
}
</script>

<style scoped>
.notifications {
  height:100vh;
  width: 20vw;
  background:black;
  border-left: 1px solid rgba(77, 77, 77, 0.452);
  border-right: 1px solid rgba(77, 77, 77, 0.452);
  text-align: left;
  padding: 20px;
  scrollbar-color: #ffffff #000000;
  scrollbar-width: none;
  overflow: auto;

}
.notificationsHead {
  font-weight: 700;font-size: 18px;margin-top: 10%;margin-bottom: 5%;
}
.notificationsList {
  display: flex;
  flex-direction: column-reverse;
  gap:20px;
}
.timeDifference {
  font-size: 12px;
  color: #FFFFFF80;
  text-align: right;
}
.notification {
  background-color: #141414;
  border: 1px solid #FFFFFF0D;
  border-radius: 8px;
  min-height: 10vh;
  display: flex;
  align-items: center;
  padding:15px;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  overflow-wrap: anywhere;
}
.notifyMessage {
  width: 100%;
}
.notification p:nth-child(1) {
  font-weight: 700;
  font-size: 18px;
}
.notification p:nth-child(2) {
  font-size: 16px;
}
.notification img {
  width:25px;
  margin-right: 15px;
}
@media (max-width:968px) {
  .notifications {
    width:100%;
    background-image: url('../assets/backgroundMobile.png');
    background-position: 0 50vh;
    background-size: 200%;
    background-repeat: no-repeat;
    position: absolute;
    top:0;
    z-index: 8;
  }
  .notificationsList {
    margin-bottom: 3%;
    overflow-y: auto;
    height:80%;
  }
  .notificationsHead {
    font-size: 24px;
  }
  .questions details summary {
    font-size: 16px;
  }
  
}
</style>
