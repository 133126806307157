<template>
  <router-view/>
</template>

<style>

#app {
  font-family: 'Proxima Nova Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  font-weight: 400;
}
html {
  background-color: #000000;
  scroll-behavior: smooth;
}
p {
  margin: 0;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
@font-face {
  font-family: 'Proxima Nova Regular';
  src: url("assets/fonts/proximanova_regular.ttf");
}
@font-face {
  font-family: 'Proxima Nova Bold';
  src: url("assets/fonts/proximanova_bold.otf");
}
* {
  box-sizing: border-box;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}
img {
  -webkit-user-drag: none;
  user-select: none;
}
a {
  text-decoration: none;
  color:#000000;
}
html {
  scrollbar-color: #ffffff #000000;
  scrollbar-width: thin;
}
.woot-widget-bubble {
  bottom:10% !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
</style>

<script>
  (function(d,t) {
    var BASE_URL="https://xsupportspanel.co";
    var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
    g.src=BASE_URL+"/packs/js/sdk.js";
    g.defer = true;
    g.async = true;
    s.parentNode.insertBefore(g,s);
    g.onload=function(){
      window.chatwootSDK.run({
        websiteToken: 'mK6DZVuRJi7VUr5hHAgS5g7s',
        baseUrl: BASE_URL
      })
    }
  })(document,"script");
</script>
