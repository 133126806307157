import store from '@/store'
import AuthPage from '@/views/AuthPage.vue'
import DashboardPage from '@/views/DashboardPage.vue'
import RegisterPage from '@/views/RegisterPage.vue'
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthPage
  },
  {
    path: '/register',
    name: 'reg',
    component: RegisterPage
  },
  {
    path: '/dashboard',
    name: 'dash',
    component: DashboardPage,
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const token = store.getters.getUserData
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (token) {
      next();
    } else {
      next('/auth');
    }
  } else {
    next();
  }
});

export default router
